import { createApiInstance } from "@/plugins/axios";
import store from "@/store";

function getAuthorizationHeaders() {
  return {
    Authorization: `Bearer ${store.getters["config/getAuthToken"]}`,
  };
}

const apiInstance = createApiInstance();

export async function apiGet(url, query) {
  const response = await apiInstance.get(url, {
    params: query,
    headers: {
      ...getAuthorizationHeaders(),
    },
  });

  return response.data;
}

export async function apiPost(url, payload) {
  const response = await apiInstance.post(url, payload, {
    headers: {
      ...getAuthorizationHeaders(),
    },
  });

  return response.data;
}

export async function apiPut(url, payload) {
  const response = await apiInstance.put(url, payload, {
    headers: {
      ...getAuthorizationHeaders(),
    },
  });

  return response.data;
}

export async function apiPutForm(url, payload) {
  const response = await apiInstance.putForm(url, payload, {
    headers: {
      ...getAuthorizationHeaders(),
    },
  });

  return response.data;
}

export async function apiDelete(url) {
  return await apiInstance.delete(url, {
    headers: {
      ...getAuthorizationHeaders(),
    },
  });
}
