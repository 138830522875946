import { apiPost } from "./index";

export function require(phone) {
  return apiPost("/api/phone/verify/request", {
    phone,
  });
}

export function submit(verification, code) {
  return apiPost("/api/phone/verify/", {
    hash: verification.hash,
    code,
  });
}
