<script>
import SvgLogo from "../../../../../components/svg/SvgLogo.vue";
import * as PhoneVerification from "../../../services/phoneVerifyService";
import CodeInput from "vue-verification-code-input";
import PasswordInput from "../../../../../components/components/Input/PasswordInput.vue";
import { apiPost } from "../../../services";

export default {
  name: "ForgetPassword",
  components: { PasswordInput, CodeInput, SvgLogo },
  data: () => ({
    phone: "",
    currentStep: 0,
    codeValue: "",
    lastError: "",
    verification: {},
    password: "",
    confirmPassword: "",
  }),
  computed: {
    isValidPhone() {
      return (number) => number.replaceAll(/\D/g, "").length === 11;
    },
  },
  methods: {
    async submitPhone() {
      if (!this.isValidPhone(this.phone)) {
        this.lastError = "Неккорректный номер телефона";
        return;
      }
      this.verification = await PhoneVerification.require(this.phone);

      this.next();

      if (this.verification.code) {
        this.lastError = `Введите ${this.verification.code}`;
      }
    },
    async submitCode() {
      await PhoneVerification.submit(this.verification, this.codeValue);

      this.next();
    },
    async reset() {
      if (this.password.length >= 6 && this.password === this.confirmPassword) {
        await apiPost("/api/auth/reset", {
          phoneHash: this.verification.hash,
          phone: this.phone,
          password: this.password,
        });

        this.next();
      } else {
        this.lastError = "Пароли не совпадают";
      }
    },
    next() {
      this.currentStep++;
      this.lastError = "";
    },
  },
};
</script>

<template>
  <div style="width: 450px" class="form vstack m-auto">
    <router-link class="d-block mb-4" to="/">
      <svg-logo></svg-logo>
    </router-link>

    <h3 class="mb-4">Личный кабинет</h3>

    <v-window :value="currentStep">
      <v-window-item>
        <label class="form__item">
          Телефон
          <input
            v-maska
            data-maska="+7(###)### ##-##"
            type="text"
            v-model="phone"
          />
        </label>

        <button class="next-button" @click="submitPhone">Далее</button>
      </v-window-item>

      <v-window-item>
        <label>
          Код подтверждения
          <code-input @complete="codeValue = $event" :fields="6" />
        </label>
        <button class="next-button" @click="submitCode">Далее</button>
        <v-divider></v-divider>
        <p class="text-start">
          Вам был отправлен код в SMS на номер <br />
          <b>{{ phone }}</b>
        </p>
      </v-window-item>

      <v-window-item>
        <label> Введите пароль </label>
        <password-input v-model="password"></password-input>
        <label> Повторите пароль </label>
        <password-input v-model="confirmPassword"></password-input>
        <button class="next-button" @click="reset">Сбросить пароль</button>
      </v-window-item>

      <v-window-item>
        <h5 class="mb-4">Восстановление пароля: пароль успешно сброшен.</h5>

        <router-link
          :to="{
            name: $router.name,
            query: {
              auth: 1,
            },
          }"
        >
          <button>Перейти в авторизации</button>
        </router-link>
      </v-window-item>
    </v-window>

    <div v-if="lastError" class="alert alert-warning mt-3">
      {{ lastError }}
    </div>
  </div>
</template>

<style scoped lang="scss">
label {
  width: 100%;
  text-align: start;
}

input[type="text"] {
  @include rounded-block;
  @include secondary-theme;
  width: 100%;
}

button {
  @include rounded-block;
  @include primary-theme;
}

.next-button {
  margin-top: 1rem;
}
</style>
