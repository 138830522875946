<template>
  <div
    :style="`max-width: ${formWidth};display: flex;flex-direction: column;justify-content: space-between;margin: auto`"
  >
    <div>
      <router-link to="/" class="d-block mb-4">
        <svg-logo style="max-width: 450px" class="w-100"></svg-logo>
      </router-link>
      <v-window :value="currentStep">
        <v-window-item>
          <label>
            <span class="form-label w-100 text-start">Телефон</span>
            <input
              v-maska
              data-maska="+7(###)### ##-##"
              v-model="phoneValue"
              type="text"
            />
          </label>
          <button class="register-step__next-button" @click="submitPhone">
            Регистрация
          </button>

          <v-divider></v-divider>
          <p>
            У вас есть аккаунт?
            <router-link
              class="text-uppercase"
              :to="{
                name: this.$route.name,
                query: { ...this.$route.query, register: undefined },
              }"
              >авторизация
            </router-link>
          </p>

          <div class="alert alert-warning mt-3" v-if="lastError">
            {{ lastError }}
          </div>
        </v-window-item>

        <v-window-item>
          <label>
            Код подтверждения
            <code-input @complete="codeValue = $event" :fields="6" />
          </label>
          <button class="register-step__next-button" @click="submitCode">
            Далее
          </button>
          <v-divider></v-divider>
          <p class="text-start">
            Вам был отправлен код в SMS на номер <br />
            <b>{{ phoneValue }}</b>
          </p>
          <label class="text-start d-flex">
            <input type="checkbox" v-model="consentAgreed" />
            <span class="ms-3">
              Согласен с
              <a href="/register-rules.html" target="_blank"
                >правилами обработки персональных данных
              </a>
            </span>
          </label>
          <div class="alert alert-warning mt-3" v-if="lastError">
            {{ lastError }}
          </div>
        </v-window-item>

        <v-window-item>
          <label> Введите пароль </label>
          <password-input v-model="password"></password-input>
          <label> Повторите пароль </label>
          <password-input v-model="confirmPassword"></password-input>
          <button class="register-step__next-button" @click="submitPassword">
            Далее
          </button>
          <v-divider></v-divider>
          <div class="alert alert-warning mt-3" v-if="lastError">
            {{ lastError }}
          </div>
        </v-window-item>

        <v-window-item>
          <h3 class="mb-4">Личный кабинет</h3>
          <h5 class="mb-4">
            Пользователь {{ phoneValue }} успешно зарегистрирован.
          </h5>

          <router-link to="/client/drivers">
            <button>Перейти в Личный кабинет</button>
          </router-link>
        </v-window-item>
      </v-window>
    </div>

    <div
      style="display: flex; justify-content: center"
      v-show="currentStep < 2"
    >
      <div class="row mt-3" style="max-width: 450px">
        <div class="col" v-for="i in 3" :key="i">
          <div
            @click="i > 2 && i - 1 < currentStep && (currentStep = i - 1)"
            :class="[
              'form-step',
              { 'form-step__active': i - 1 === currentStep },
              i > 2 && i - 1 < currentStep && 'form-step__clickable',
              i - 1 < currentStep && 'form-step__passed',
            ]"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import { mapActions, mapGetters, mapMutations } from "vuex";
import SvgLogo from "@/components/svg/SvgLogo";
import PasswordInput from "../../../../../components/components/Input/PasswordInput.vue";

export default {
  name: "RegisterForm",
  components: { PasswordInput, SvgLogo, CodeInput },
  data: () => ({
    phoneValue: "",
    codeValue: "",
    currentStep: 0,
    credentials: null,
    currentDriver: 0,
    consentAgreed: false,
    password: "",
    confirmPassword: "",
  }),
  computed: {
    ...mapGetters("register", ["lastError", "allDrivers"]),
    formWidth() {
      // if (this.currentStep === 3) {
      //   return "850px";
      // }
      return `450px`;
    },
  },
  methods: {
    ...mapActions("register", [
      "enterPhone",
      "enterCode",
      "enterCredentials",
      "enterPassword",
      "register",
    ]),
    ...mapMutations("register", ["setError"]),
    ...mapActions("config", ["setAuthToken"]),
    submitPhone() {
      this.enterPhone({ phone: this.phoneValue }).then((success) => {
        if (success) {
          this.next();
        }
      });
    },

    async submitPassword() {
      if (this.password !== this.confirmPassword) {
        this.setError("Пароли не совпадают");
        return;
      }
      const success = await this.enterPassword({ password: this.password });
      if (success) {
        await this.submitRegistration();
      }
    },

    async submitCode() {
      if (!this.consentAgreed) {
        this.setError("Примите согласие на обработку персональных данных");
        return;
      }
      const success = await this.enterCode({ code: this.codeValue });
      if (success) {
        this.next();
      }
    },
    async submitRegistration() {
      try {
        const token = await this.register();
        await this.setAuthToken(token);
        this.next();
      } catch (e) {
        this.error = e.message;
      }
    },
    next() {
      this.currentStep++;
    },
  },
};
</script>

<style scoped lang="scss">
label {
  width: 100%;
}

input[type="text"] {
  @include rounded-block;
  @include secondary-theme;
  width: 100%;
}

button {
  @include rounded-block;
  @include primary-theme;
}

label {
  text-align: start;
}

.register-step__next-button {
  margin-top: 1em;
}

.form-step {
  width: 100%;
  height: 7px;
  border-radius: 4px;
  background: #cccccc;
  transition: background-color 0.2s ease-out;
}

.form-step__active {
  background: #ff6600;
}

.form-step__clickable {
  background: lighten(#ff6600, 15%) !important;
  cursor: pointer;
}

.form-step__passed {
  background: lighten(#ff6600, 30%);
}
</style>
