import axios from "axios";
import { apiService } from "@/services/apiService";

export function createApiInstance() {
  return axios.create({
    baseURL: `${
      process.env.VUE_APP_AXIOS_SSL === "true" ? "https" : "http"
    }://${process.env.VUE_APP_AXIOS_DOMAIN}`,
  });
}

const baseInstance = createApiInstance();
const apiPrefix = "api";
export default {
  baseInstance,
  api: apiService(baseInstance)([
    ["news", "get", apiPrefix, ["articles"]],
    ["stocks", "get", apiPrefix, ["stocks"]],
    ["cities", "get", apiPrefix, ["regions"]],
    ["banners", "get", apiPrefix, ["banners"]],
    ["cars", "get", apiPrefix, ["cars"]],
    ["freeCars", "get", apiPrefix, ["cars", "free"]],
    ["mainStock", "get", apiPrefix, ["stock", "main"]],
    ["faq", "get", apiPrefix, ["faqs"]],
    ["sitesByRegion", "get", apiPrefix, ["region", "sites"]],
    ["maps", "get", apiPrefix, ["sites"]],
    ["rates", "get", apiPrefix, ["cars", "rates"]],
    ["sendCar", "post", apiPrefix, ["order"]],
    ["rules", "get", apiPrefix, ["include", "rentrules"]],
    ["login", "post", apiPrefix, ["auth", "login"]],
    ["registration", "post", apiPrefix, ["auth", "registration"]],
    ["me", "post", apiPrefix, ["auth", "me"]],
    ["refreshToken", "post", apiPrefix, ["auth", "refresh"]],
    [
      "requestPhoneVerifyCode",
      "post",
      apiPrefix,
      ["phone", "verify", "request"],
    ],
    ["verifyPhoneCode", "post", apiPrefix, ["phone", "verify"]],
  ]),
};
