<template>
  <form class="form vstack m-auto" @submit.prevent="auth">
    <router-link to="/" class="d-block mb-4">
      <svg-logo></svg-logo>
    </router-link>

    <div class="mb-2">
      <label class="form__item">
        Телефон
        <input
          v-maska
          data-maska="+7(###)### ##-##"
          type="text"
          v-model="phone"
        />
      </label>
    </div>

    <div class="mb-2">
      <label class="form__item">
        Пароль
        <input type="password" v-model="password" />
      </label>
    </div>

    <button class="mt-3" type="submit">Войти</button>

    <div class="alert alert-warning mt-3" v-if="lastError">{{ lastError }}</div>

    <router-link
      class="mt-4"
      :to="{
        name: $route.name,
        query: { ...$route.query, forget: true },
      }"
      >Забыли пароль?</router-link
    >
    <v-divider></v-divider>
    <p>
      У вас нет аккаунта ?
      <router-link
        class="text-uppercase"
        :to="{
          name: this.$route.name,
          query: { ...this.$route.query, register: true },
        }"
        >регистрация</router-link
      >
    </p>
  </form>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import SvgLogo from "@/components/svg/SvgLogo";
import {detail, updateInfo} from "../../../services/clientService";
import moment from "@/plugins/moment";

export default {
  name: "AuthForm",
  components: { SvgLogo },
  data: () => ({
    phone: "",
    password: "",
    lastError: "",
  }),
  methods: {
    ...mapActions("user", ["authViaCredentials", "authViaToken"]),
    ...mapMutations("user", { authUser: "auth" }),
    ...mapActions("config", ["setAuthToken"]),
    async auth() {
      try {
        const response = await this.authViaCredentials({
          phone: this.phone,
          password: this.password,
        });
        await this.setAuthToken(response.access_token);
        {
          await updateInfo()
          localStorage.setItem('update-info-timestamp',moment.now().toString())

          const response = await detail();
          await this.authUser(response);
        }
        await this.$router.push("/client/dashboard/");
      } catch (e) {
        this.lastError = e.message;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  max-width: 450px;
  width: 100%;

  &__item {
    @include vstack(flex-start);

    input {
      @include rounded-block;
      @include secondary-theme;
    }
  }

  button {
    @include rounded-block;
    @include primary-theme;
    width: 100%;
    padding: 14px;
  }
}
</style>
