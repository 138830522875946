<template>
  <div class="popup">
    <div class="popup__close" @click="closeCar">
      <svg-cross />
    </div>

    <div
      class="popup__wrapper d-flex align-items-center justify-content-center"
    >
      <div class="popup__content container">
        <div class="mt-4 h-100 w-100">
          <component class="h-100" :is="authComponent"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgLogo from "@/components/svg/SvgLogo";
import SvgCross from "@/components/svg/SvgCross";
import AuthForm from "@/modules/client/components/widgets/auth/AuthForm";
import RegisterForm from "@/modules/client/components/widgets/auth/RegisterForm";
import ForgetPassword from "@/modules/client/components/widgets/auth/ForgetPassword.vue";
export default {
  name: "AuthModal",
  components: { AuthForm, SvgCross, SvgLogo },
  mounted() {
    document.body.style.overflow = "hidden";
    document.getElementById("popup-bg").classList.add("active");
    document.addEventListener("click", this.clickToWrap);
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
    document.getElementById("popup-bg").classList.remove("active");
    document.removeEventListener("click", this.clickToWrap);
  },
  computed: {
    authComponent() {
      if (this.$route.query.register) {
        return RegisterForm;
      }
      if (this.$route.query.forget) {
        return ForgetPassword;
      }
      return AuthForm;
    },
  },
  methods: {
    clickToWrap(e) {
      if (e.target.classList.contains("popup")) {
        this.closeCar();
      }
    },
    closeCar() {
      return this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, auth: undefined },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.popup {
  width: 100%;
  left: 0;
  //background-color: rgba(#000, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-modal;
  bottom: 0;
  &__wrapper {
    background-color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 950px;
    width: 50vw;
    min-width: 320px;
    overflow-y: auto;
    overflow-x: visible;

    @include media(936) {
      width: 100vw;
    }
  }
  &__close {
    position: absolute;
    top: 10px;
    left: 15vw;
    cursor: pointer;
    display: block;
    z-index: 30;
    background: gray;
    border-radius: 50%;
    @include media(768) {
      display: block;
      svg {
        height: auto;
        max-width: 30px;
      }
    }
  }
  &__content {
    max-height: 750px;
    height: 100%;

    @include vstack(center);

    text-align: center;
    svg {
      max-width: 100%;
      height: auto;
    }
    p {
      font-weight: 700;
      font-size: em(27);
      text-align: center;

      color: #171930;
    }
  }
}
</style>
