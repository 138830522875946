<template>
  <div class="picker__container">
    <div
      class="picker__clear"
      v-if="$route.query.from && $route.query.to && clearDatesProp"
      @click="clearDates"
    >
      Очистить даты
    </div>
    <vv-date-picker
      v-model="getDate"
      mode="dateTime"
      :popover="{
        placement: width > 768 ? `right` : 'top',
        positionFixed: `true`,
      }"
      is-range
      is24hr
      :min-date="minDate"
      gi
      :valid-hours="{ min: 9, max: 19 }"
      :minute-increment="10"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div class="row picker" v-on="inputEvents.start">
          <div class="col-6 picker__wrap">
            <div class="picker__item">
              <div class="picker__top d-flex align-items-center">
                <span class="picker__circle picker__circle_green" />
                <div class="picker__title">Начало</div>
              </div>
              <div class="picker__content pt-2">
                <div class="d-flex align-items-center">
                  <span class="picker__info">
                    {{ getStartDate }}
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.00025 5.39982C4.65025 5.39982 4.30025 5.26482 4.03525 4.99982L0.775254 1.73982C0.630254 1.59482 0.630254 1.35482 0.775254 1.20982C0.920254 1.06482 1.16025 1.06482 1.30525 1.20982L4.56525 4.46982C4.80525 4.70982 5.19525 4.70982 5.43525 4.46982L8.69525 1.20982C8.84025 1.06482 9.08025 1.06482 9.22525 1.20982C9.37025 1.35482 9.37025 1.59482 9.22525 1.73982L5.96525 4.99982C5.70025 5.26482 5.35025 5.39982 5.00025 5.39982Z"
                        fill="#1A202C"
                        stroke="#1A202C"
                        stroke-width="0.5"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 picker__wrap">
            <div class="picker__item">
              <div class="picker__top d-flex align-items-center">
                <span class="picker__circle picker__circle_red" />
                <div class="picker__title">Окончание</div>
              </div>
              <div class="picker__content pt-2">
                <div class="d-flex align-items-center">
                  <span class="picker__info">
                    {{ getEndDate }}
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.00025 5.39982C4.65025 5.39982 4.30025 5.26482 4.03525 4.99982L0.775254 1.73982C0.630254 1.59482 0.630254 1.35482 0.775254 1.20982C0.920254 1.06482 1.16025 1.06482 1.30525 1.20982L4.56525 4.46982C4.80525 4.70982 5.19525 4.70982 5.43525 4.46982L8.69525 1.20982C8.84025 1.06482 9.08025 1.06482 9.22525 1.20982C9.37025 1.35482 9.37025 1.59482 9.22525 1.73982L5.96525 4.99982C5.70025 5.26482 5.35025 5.39982 5.00025 5.39982Z"
                        fill="#1A202C"
                        stroke="#1A202C"
                        stroke-width="0.5"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </vv-date-picker>
  </div>
</template>

<script>
import moment from "@/plugins/moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { DatePicker } from "v-calendar";

export default {
  name: "SideBarCalendar",
  components: {
    VvDatePicker: DatePicker,
  },
  props: {
    button: {
      type: String,
      default: "Подобрать",
    },
    clearDatesProp: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      range: {
        start: null,
        end: null,
      },
      minDate: moment().add(2, "hour").toISOString(),
    };
  },
  computed: {
    ...mapGetters("config", { getDateStore: "getDate" }),
    width() {
      return window.innerWidth;
    },
    getRoute() {
      return this.$route.name;
    },
    getDate: {
      get() {
        if (!this.getDateStore) {
          return {
            start: null,
            end: null,
          };
        }
        return this.getDateStore;
      },
      set(val) {
        if (val) {
          this.changeDate({
            start: moment(val.start).toISOString(),
            end: moment(val.end).toISOString(),
          });
          this.$emit("changeStep", 3);
        } else {
          this.changeDate(null);
        }
      },
    },
    getStartDate() {
      return this.getDate.start
        ? this.$moment(this.getDate.start).format("DD.MM.YYYY HH:mm")
        : "Выберите дату";
    },
    getEndDate() {
      return this.getDate.end
        ? this.$moment(this.getDate.end).format("DD.MM.YYYY HH:mm")
        : "Выберите дату";
    },
  },
  watch: {
    getRoute() {
      this.changeDate(null);
    },
  },
  methods: {
    ...mapMutations("config", ["changeDate"]),
    ...mapActions("car", ["loadFreeCars"]),
    // async changeDate() {
    //   if (this.$route.name !== "Home" && this.$route.name !== "Catalog") {
    //     await this.$router.push({ name: "Catalog" });
    //   }
    //   if (!this.$route.query.from || !this.$route.query.to) {
    //     await this.$router.push({
    //       name: this.$route.name,
    //       query: {
    //         ...this.$route.query,
    //         from: moment(this.getDate.start).toISOString(),
    //         to: moment(this.getDate.end).toISOString(),
    //       },
    //     });
    //   }
    //   await this.loadFreeCars({
    //     from: this.$route.query.from,
    //     to: this.$route.query.to,
    //   });
    // },
    clearDates() {
      this.getDate = null;

      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, from: undefined, to: undefined },
      });
      this.$emit("changeStep", 1);
    },
  },
};
</script>

<style scoped lang="scss">
.picker {
  &__wrap {
    &:first-child {
      padding-right: calc(var(--bs-gutter-x) * 0.2);
    }
    &:last-child {
      padding-left: calc(var(--bs-gutter-x) * 0.2);
    }
  }
  &__item {
    background: #ffffff;
  }
  &__circle {
    width: 16px;
    height: 16px;
    margin-right: 10px;

    background: rgba(53, 99, 233, 0.3);
    display: inline-block;
    border-radius: 10em;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 10em;
    }
    &_green {
      &::before {
        background: #1ee03d;
      }
    }
    &_red {
      &::before {
        background: #e01e1e;
      }
    }
  }
  &__title {
    font-weight: 500;
    font-size: em(14);
    letter-spacing: -0.02em;

    color: #656565;
  }
  &__line {
    border: 1px solid rgba(195, 212, 233, 0.4);
    width: 0;
    height: 20px;
  }
  &__info {
    outline: none;
    border: none;
    border-radius: 0;
    font-weight: 700;
    font-size: em(13);
    margin: 0;
    padding: 0;
    color: #040404;
    svg {
      margin-left: 2px;
    }
    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
  &__container {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px 10px;
    position: relative;
    @include media(1200) {
      max-width: 600px;
      margin: 0 auto;
    }
  }
  &__clear {
    position: absolute;
    top: -24px;
    text-decoration: underline;
    color: #ff6600;
    right: 0;
    font-size: em(10);
    cursor: pointer;
  }
}
</style>
