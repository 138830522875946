<script>
export default {
  name: "PasswordInput",
  props: {
    value: {
      required: true,
    },
  },
  data: () => ({
    hidden: true,
  }),
};
</script>

<template>
  <div class="input d-flex">
    <input
      :type="hidden ? 'password' : 'text'"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
    <i
      @click="hidden = !hidden"
      :class="['mdi', hidden ? 'mdi-eye-closed' : 'mdi-eye']"
    ></i>
  </div>
</template>

<style scoped lang="scss">
.input {
  @include rounded-block;
  @include secondary-theme;
}
input {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 21px;
}
label {
  text-align: start;
  width: 100%;
}
</style>
