import Cookies from "js-cookie";
import axios from "@/plugins/axios";
import { find } from "lodash";
export default {
  namespaced: true,
  state: {
    city: Cookies.get("city") || null,
    citiesList: [],
    site: Cookies.get("site") || null,
    date: null,
    authToken: Cookies.get("token") || null,
  },
  getters: {
    getCity(state) {
      return state.city;
    },
    getCityList(state) {
      return state.citiesList;
    },
    getActiveCity(state) {
      if (!state.city) return {};
      return (
        find(state.citiesList, (city) => city.id === Number(state.city)) || {}
      );
    },
    getSites(state, getters) {
      return getters.getActiveCity.sites || [];
    },
    getSiteById(state) {
      return (guid) =>
        state.citiesList
          .flatMap((city) => city.sites)
          .find((site) => site.id == guid);
    },
    getDate(state) {
      return state.date;
    },
    getSite: (state) => state.site,
    getActiveSite(state, getters) {
      if (!getters.getActiveCity.name) return {};
      return (
        find(
          getters.getActiveCity.sites,
          (site) => site.id === Number(state.site)
        ) || {}
      );
    },
    getAuthToken(state) {
      return state.authToken;
    },
  },
  mutations: {
    changeCity(state, payload) {
      Cookies.set("city", payload, { expires: 14 });
      const city =
        find(state.citiesList, (site) => site.id === Number(payload)) || [];
      state.city = payload;
      state.site = city.sites[0]?.id;
      Cookies.set("site", state.site);
    },
    loadCityList(state, payload) {
      return (state.citiesList = payload);
    },
    changeSite(state, payload) {
      Cookies.set("site", payload, { expires: 14 });
      return (state.site = payload);
    },
    changeDate(state, payload) {
      state.date = payload;
    },
    setAuthToken(state, token) {
      Cookies.set("token", token, { expires: 14 });
      state.authToken = token;
    },
  },
  actions: {
    async loadCity({ commit }) {
      try {
        const response = await axios.api.cities({});
        commit("loadCityList", Object.values(response.data));
      } catch (e) {
        console.log(e);
      }
    },
    setAuthToken({ commit }, token) {
      commit("setAuthToken", token);
    },
  },
};
