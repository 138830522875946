import { apiGet } from "../services";

export default {
  namespaced: true,
  state: {
    files: [],
  },
  getters: {
    allFiles(state) {
      return state.files;
    },
  },
  mutations: {
    setFiles(state, files) {
      state.files = files;
    },
  },
  actions: {
    async fetchAll({ commit }) {
      const files = await apiGet("/api/personal/media");

      commit("setFiles", files);
    },
  },
};
