import { definePlugin } from "../../../plugins/plugin";
import {detail, updateInfo} from "../services/clientService";
import moment from "@/plugins/moment";

export const CheckSession = definePlugin(async ({ store, router }) => {
  if (store.getters["config/getAuthToken"]) {
    try {
      const lastUpdateTime = localStorage.getItem('update-info-timestamp')

      if(!lastUpdateTime || new moment().add(-30,'minute').diff(+lastUpdateTime) > 0) {
        await updateInfo()
        localStorage.setItem('update-info-timestamp',moment.now().toString())
      }

      const response = await detail();

      store.commit("user/auth", response);
    } catch (e) {
      store.commit("config/setAuthToken", "");
    }
  }

  router.beforeEach((to, from, next) => {
    if (to.meta.auth === true && !store.getters["user/isAuth"]) {
      return next("/?auth=1");
    }

    if ("logout" in to.query) {
      store.commit("config/setAuthToken", "");
      window.location.href = "/";
    }

    next();
  });
});
