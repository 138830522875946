<template>
  <div>
    <div class="city">
      <div class="city__active">
        <span @click="showList = !showList"
          >{{ activeCity.name || "Выберите регион" }}
          <svg
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.95877 0.49756C1.76855 0.502636 1.58372 0.561815 1.42599 0.668266C1.26826 0.774717 1.14417 0.923981 1.06829 1.09849C0.992417 1.273 0.967904 1.46556 0.99763 1.65352C1.02736 1.84147 1.11009 2.01706 1.23612 2.15964L7.23026 9.16551C7.32415 9.27553 7.44078 9.36388 7.57212 9.42446C7.70345 9.48503 7.84636 9.51638 7.991 9.51638C8.13563 9.51638 8.27855 9.48503 8.40988 9.42446C8.54122 9.36388 8.65785 9.27553 8.75174 9.16551L14.7517 2.15964C14.8425 2.06068 14.9125 1.94445 14.9574 1.81788C15.0023 1.69131 15.0212 1.557 15.0131 1.42295C15.005 1.2889 14.9699 1.1578 14.9101 1.03759C14.8502 0.917378 14.7667 0.810541 14.6646 0.723284C14.5625 0.636027 14.4439 0.570163 14.3159 0.52974C14.1878 0.489317 14.0529 0.475147 13.9192 0.487986C13.7855 0.500826 13.6558 0.540421 13.5378 0.604482C13.4197 0.668542 13.3158 0.755679 13.2322 0.860772L7.99002 6.9799L2.74979 0.860772C2.65383 0.744536 2.53284 0.651576 2.39587 0.58868C2.25889 0.525784 2.10947 0.494581 1.95877 0.49756Z"
              fill="#FF6500"
            />
          </svg>
        </span>
      </div>
      <ul class="city__list" v-if="showList">
        <li
          @click="activeCity = city.id"
          v-for="city in getCityList"
          :key="city.id"
          :class="city.id === activeCity.id ? 'active' : ''"
        >
          {{ city.name }}
        </li>
      </ul>
    </div>
    <div class="city__sites mt-1">
      <div class="overflow-hidden">
        <div class="col-12" v-for="siteItem in getSites" :key="siteItem.id">
          <label class="form-check d-flex align-items-start mx-2">
            <input
              :value="siteItem.id"
              type="radio"
              v-model="site"
              class="form-check-input"
            />
            <span class="city__site form-check-label">{{
              siteItem.address
            }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters, mapMutations } from "vuex";
// import geoService from "@/services/geoService";

export default {
  name: "SideBarCity",
  data() {
    return {
      showList: false,
    };
  },
  computed: {
    ...mapGetters("config", [
      "getCity",
      "getCityList",
      "getActiveCity",
      "getSites",
      "getSite",
    ]),
    activeCity: {
      get() {
        return this.getActiveCity;
      },
      set(val) {
        this.changeCity(val);
        this.showList = false;
      },
    },
    site: {
      get() {
        return this.getSite;
      },
      set(val) {
        if (val !== "undefined" && typeof val !== "undefined") {
          this.changeSite(val);
          this.$emit("changeStep", 2);
        }
      },
    },
  },
  async mounted() {
    this.site = Cookies.get("site") || this.getActiveCity?.sites?.[0]?.id;
  },

  methods: {
    ...mapMutations("config", ["changeCity", "changeSite"]),
    ...mapActions("config", ["loadCity"]),
  },
};
</script>

<style scoped lang="scss">
.city {
  position: relative;

  &__active {
    font-weight: bold;
    color: #2d3134;

    span {
      cursor: pointer;
    }
  }

  &__list {
    padding: 10px;
    list-style: none;
    position: absolute;
    top: 100%;
    z-index: 10000;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #969696;

    @include media(900) {
      height: auto;
      overflow: scroll;
      max-height: 30vh;
      bottom: 0;
      min-height: 30vh;
    }
    @include media(380) {
      height: auto;
      overflow: scroll;
      max-height: 30vh;
      bottom: 0;
      min-height: 20vh;
    }

    li {
      padding: 3px 0;
      cursor: pointer;
    }

    .active {
      font-weight: bold;
    }
  }
  &__site {
    font-size: em(14);
    color: #2d3134;
    padding-left: 10px;
  }
  &__sites {
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    @include media(1200) {
      max-height: 100%;
    }
  }
}
.form-check-input:checked {
  background-color: #ff6600;
  border-color: #ff6600;
}
.form-check-input {
  min-width: 16px;
}
</style>
