<script>
import { mapGetters } from "vuex";

export default {
  name: "NotificationView",
  computed: {
    ...mapGetters("popup", ["allNotifications"]),
  },
};
</script>

<template>
  <div>
    <v-snackbar
      v-for="popup in allNotifications"
      :timeout="2000"
      :key="popup.created_at"
      rounded="pill"
      color="blue-gray"
      :value="true"
    >
      {{ popup.text }}
    </v-snackbar>
  </div>
</template>

<style scoped lang="scss"></style>
