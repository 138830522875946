import user from "./user";
import register from "./register";
import drivers from "./drivers";
import rent from "./rent";
import notifications from "./notifications";
import media from "./media";
import popup from "@/modules/client/store/popup";

export const createClientModule = () => ({
  modules: {
    user,
    register,
    drivers,
    rent,
    notifications,
    popup,
    media,
  },
});
