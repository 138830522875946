import { login, save, order } from "../services/clientService";

export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    isAuth(state) {
      return !!state.user;
    },
    user(state) {
      return state.user;
    },
    client(state) {
      return state.user.client
    },
    currentRent() {
      return {
        car: {
          name: "KIA RIO седан 2018г.",
          number: "А 223 ОР",
        },
        rate: {
          distance: 284,
          days: 3,
          price: 1300,
        },
      };
    },
  },
  mutations: {
    auth(state, payload) {
      state.user = payload;
    },
    setClient(state, client) {
      state.user.client = client;
    },
    setDriver(state, driver) {
      state.user.main_driver = driver;
    },
  },
  actions: {
    async authViaCredentials(state, { phone, password }) {
      try {
        return await login({ phone, password });
      } catch (e) {
        throw new Error("Аккаунт с таким логином и паролем не найден.");
      }
    },
    async save(state, payload) {
      const response = await save(payload.form, payload.id);
      state.commit("setClient", response.client);
      state.commit("setDriver", response.driver);
      return response;
    },
    async order(state, payload) {
      const response = await order(payload);

      return response;
    },
  },
};
