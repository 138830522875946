<template>
  <svg
    width="62"
    height="62"
    padding="12px"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="padding: 12px"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.396 31.0909L58.9647 59.6202L59.7035 58.8822L31.1348 30.353L59.7035 1.82387L58.9647 1.08594L30.396 29.6152L1.82627 1.08594L1.0874 1.82384L29.6561 30.353L1.0874 58.8823L1.82627 59.6202L30.396 31.0909Z"
      fill="black"
      stroke="black"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: "SvgCross",
};
</script>

<style scoped></style>
