import axios from "@/plugins/axios";
import Axios from "axios";
import { apiPost } from "../services";

export default {
  namespaced: true,
  state: {
    phone: "",
    lastError: "",
    phoneHash: "",
    credentials: {},
    password: "",
  },
  getters: {
    isValidPhone() {
      return (number) => number.replaceAll(/\D/g, "").length === 11;
    },
    lastError(state) {
      return state.lastError;
    },
    allDrivers(state) {
      return state.drivers;
    },
    registerData(state) {
      return {
        phone: state.phone,
        drivers: state.drivers,
      };
    },
  },
  mutations: {
    setPhone(state, phone) {
      state.phone = phone;
    },
    setCode(state, code) {
      state.code = code;
    },
    setError(state, message) {
      state.lastError = message;
    },
    setPhoneHash(state, hash) {
      state.phoneHash = hash;
    },
    setPassword(state, password) {
      state.password = password;
    },
    setCredentials(state, credentials) {
      state.credentials = credentials;
    },
  },
  actions: {
    async enterPhone({ commit, getters }, { phone }) {
      if (!getters.isValidPhone(phone)) {
        commit("setError", "Номер телефона содержит ошибку");
        return false;
      }

      const response = await axios.api.requestPhoneVerifyCode({
        payload: {
          phone: phone,
        },
      });

      commit("setError", "");
      if (response.data.code) {
        commit(
          "setError",
          `Для продолжения регистрации введите ${response.data.code}`
        );
      }
      commit("setPhoneHash", response.data.hash);
      commit("setPhone", phone);
      return true;
    },
    async enterCredentials(context, credentials) {
      const { commit } = context;
      commit("setCredentials", credentials);
    },

    async enterPassword({ commit }, { password }) {
      if (password.length < 6) {
        commit("setError", "Пароль должен быть не менее 6 символов");
        return false;
      }
      commit("setPassword", password);
      return true;
    },

    async enterCode(context, { code }) {
      const { commit, state } = context;

      if (code.length < 6) {
        commit("setError", "Код введен не верно");
        return false;
      }

      try {
        await axios.api.verifyPhoneCode({
          payload: {
            hash: state.phoneHash,
            code: code,
          },
        });

        commit("setError", "");
        return true;
      } catch (e) {
        if (e instanceof Axios.AxiosError) {
          commit("setError", e.response.data.message);
          return false;
        }
      }
    },

    async register({ state, commit }) {
      const response = await apiPost("/api/auth/registration", {
        phone: state.phone,
        drivers: state.drivers,
        credentials: state.credentials,
        phoneHash: state.phoneHash,
        password: state.password,
      }).catch((e) => {
        if (e instanceof Axios.AxiosError) {
          // throw new Error(e.response.data.message);
          commit("setError", e.response.data.message);
        }
      });

      if (response.auth) {
        return response.auth.access_token;
      }
    },
  },
};
