<template>
  <ul class="menu">
    <router-link
      v-for="(item, index) in menu"
      :key="index"
      :to="item.link"
      tag="li"
      class="menu__item"
    >
      <a class="menu__link">{{ item.name }}</a>
    </router-link>
    <!--    <li class="menu__item menu__item_auth">-->
    <!--      &lt;!&ndash;      <a href="#" class="menu__link">Войти</a>&ndash;&gt;-->
    <!--    </li>-->
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SideBarMenu",
  data() {
    return {
      menuBase: [
        {
          name: "Каталог авто",
          link: "/catalog",
        },
        {
          name: "Новости",
          link: "/news",
        },
        {
          name: "Акции",
          link: "/events",
        },
        {
          name: "Тарифы",
          link: "/tariffs",
        },
        {
          name: "Правила проката",
          link: "/rental-rules",
        },
        {
          link: "/presence-card",
          name: "Карта присутствия",
        },
        {
          name: "Контакты",
          link: "/contacts",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("user", ["isAuth"]),
    menu() {
      let menu = this.menuBase;

      menu = menu.slice();
      menu.push({
        name: "Личный кабинет",
        link: this.$router.resolve({ name: "ClientDashboard" }).href,
      });

      return menu;
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    padding: 1vh 0;

    &_auth {
      padding-top: 3vh;
    }

    &.active {
      .menu__link {
        font-weight: bold;
        color: #000;
      }
    }
  }

  &__link {
    text-decoration: none;
    font-size: em(18);
    color: #717579;
  }
}
</style>
