import Vue from "vue";
import Vuex from "vuex";
import config from "../store/config";
import car from "../store/car";
import content from "../store/content";
import { createClientModule } from "../modules/client/store";

Vue.use(Vuex);

const clientModule = createClientModule();

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    config,
    car,
    content,
    ...clientModule.modules,
  },
});
