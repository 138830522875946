// import axios from "@/plugins/axios";

import { RentService } from "@/modules/client/services/rentService";
// import _ from "lodash";

export default {
  namespaced: true,
  state: {
    rent: undefined,
  },
  getters: {
    getRentHistory(state) {
      return state.rent;
    },
  },
  mutations: {
    setRents(state, rentList) {
      state.rent = rentList.sort((a,b)=>b.application_id - a.application_id).map(i=>{
        i.application_id = i.application_id?.toString().padStart(9,'0')
        return i
      });
    },
  },
  actions: {
    async loadRents({ commit }) {
      try {
        const response = await RentService.index();
        commit("setRents", response);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
