import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "normalize.css";
import "@/assets/styles/global.scss";
import "@/assets/css/materialdesignicons.css";
import "@/plugins/axios";
import "@/plugins/fancyapps";
import "bootstrap/dist/css/bootstrap.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "@/plugins/moment";
import "@/plugins/components";
import axios from "@/plugins/axios";
import VueScrollTo from "vue-scrollto";
import "vue2-animate/dist/vue2-animate.min.css";

import VueSocialSharing from "vue-social-sharing";

import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";

Vue.use(VueScrollTo);
Vue.use(VueSocialSharing);
Vue.use(VueFileAgent);

import { plugins } from "./plugins";

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

(async function () {
  const settings = {};
  for (let plugin of plugins) {
    await plugin({ store, router, settings });
  }

  const app = new Vue({
    router,
    store,
    ...settings,
    render: (h) => h(App),
  });

  app.$mount("#app");
})();
