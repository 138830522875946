import { forEach } from "lodash";
import store from "@/store";

function getAuthorizationHeaders() {
  return {
    Authorization: `Bearer ${store.getters["config/getAuthToken"]}`,
  };
}

export const apiService = (baseInstance) => (array) => {
  const methods = {};
  array.forEach(
    ([funcName, type, prefix, aliases]) =>
      (methods[funcName] = ({ url, payload, query, id = {} } = {}, config) => {
        if (url) return baseInstance[type](url, config);
        const newAliases = [];

        forEach(aliases, (alias) => {
          newAliases.push(alias);
          if (id[alias]) {
            newAliases.push(id[alias]);
          }
        });
        if (type === "get") {
          return baseInstance[type](`${prefix}/${newAliases.join("/")}`, {
            params: query,
            body: payload,
          });
        } else {
          return baseInstance[type](
            `${prefix}/${newAliases.join("/")}`,
            payload,
            {
              ...config,
              headers: {
                ...getAuthorizationHeaders(),
              },
            }
          );
        }
      })
  );
  return methods;
};
