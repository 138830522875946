<template>
  <v-app>
    <v-layout>
      <side-bar v-model="drawer" />

      <div class="container-fluid">
        <v-app-bar
          flat
          color="white"
        >
          <v-app-bar-nav-icon
            class="d-block d-md-none"
            variant="text"
            @click.stop="drawer = !drawer"
          />

          <v-col class="d-none d-md-block">
            <h3 class="m-0">
              {{ $route.meta.title || "Карточка клиента" }}
            </h3>
          </v-col>

          <v-spacer class="d-none d-md-block"></v-spacer>

          <v-btn
            href="tel:+79092444949"
            color="primary"
            text
          >
            +7 (909) 244-49-49
          </v-btn>

          <v-btn icon>
            <v-icon>mdi-magnify</v-icon>
          </v-btn>

          <v-btn icon>
            <v-icon>mdi-bell</v-icon>
            <v-badge
              v-if="hasUnreadNotification"
              bordered
              dot
              overlap
            ></v-badge>
          </v-btn>

          <v-btn icon>
            <v-icon>mdi-email</v-icon>
            <v-badge
              bordered
              dot
              overlap
            ></v-badge>
          </v-btn>

          <router-link to="/">
            <v-btn
              outlined
              text
            >На сайт</v-btn>
          </router-link>
        </v-app-bar>
        <v-main>
          <v-col>
            <h3 class="m-0 d-block d-md-none">
              {{ $route.meta.title || "Карточка клиента" }}
            </h3>
          </v-col>
          <slot />
          <notification-view></notification-view>
        </v-main>
      </div>
    </v-layout>
  </v-app>
</template>

<script>
import SideBar from "@/modules/client/components/SideBar";
import { mapActions, mapGetters } from "vuex";
import NotificationView from "@/modules/client/components/NotificationView.vue";

export default {
  name: "ClientLayout",
  components: { NotificationView, SideBar },
  data: () => ({
    drawer: window.innerWidth > 959,
  }),
  methods: {
    ...mapActions("notifications", ["fetchNotifications"]),
  },
  computed: {
    ...mapGetters("user", ["isAuth"]),
    ...mapGetters("notifications", ["hasUnreadNotification"]),
  },
  mounted() {
    this.fetchNotifications();
  },
};
</script>

<style scoped></style>
