export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  getters: {
    allNotifications(state) {
      return state.notifications;
    },
  },
  mutations: {
    addPopup(state, popup) {
      state.notifications.push(popup);
    },
  },
  actions: {
    error(state, text) {
      state.commit("addPopup", {
        text,
        created_at: +new Date(),
      });
    },
  },
};
