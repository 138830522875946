// import axios from "@/plugins/axios";

import _ from "lodash";
import { DriversService } from "../services/driversService";

export default {
  namespaced: true,
  state: {
    drivers: undefined,
  },
  getters: {
    getDrivers(state) {
      return state.drivers;
    },
    getDriverById(state) {
      return (id) => {
        return state.drivers.find((driver) => driver.id === id);
      };
    },
  },
  mutations: {
    setDrivers(state, driverList) {
      state.drivers = driverList;
    },
    updateDriver(state, driver) {
      const index = state.drivers.findIndex((d) => d.id === driver.id);
      state.drivers[index] = driver;
    },
    createDriver(state) {
      state.drivers.unshift({
        id: 0,
        name: "",
        lastname: "",
        surname: "",
        commentary: "Новый водитель",
        experience: "",
        status: {
          id: null,
          name: "Не сохранён",
          code: "local",
        },
        document: {
          issue_date: "",
          issuedBy: "",
          series: "",
          number: "",
          photos: [],
        },
        license: {
          issue_date: "",
          issuedBy: "",
          series: "",
          number: "",
          photos: [],
        },
      });
    },
  },
  actions: {
    async loadDrivers({ commit }) {
      try {
        const response = await DriversService.index();

        _.each(response, (item) => {
          if (item.document.photos === "null") item.document.photos = null;
          if (item.license.photos === "null") item.license.photos = null;
        });

        commit("setDrivers", response);
      } catch (error) {
        commit("setDrivers", []);
        console.log(error);
      }
    },
    async saveDriver({ commit }, driver) {
      const response = await DriversService.save(driver);
      commit("updateDriver", response.driver);
    },
    async deleteDrivers({ state, commit }, removeList) {
      const removeIds = removeList.map((driver) => driver.id);

      await DriversService.delete(removeIds);
      commit("setDrivers", _.difference(state.drivers, removeList));
    },
  },
};
