import { apiGet, apiPost } from "./index";

const SERVICE_URL = "/api/personal/driver";

export const DriversService = {
  index() {
    return apiGet(SERVICE_URL);
  },
  delete(idList) {
    return apiPost(`${SERVICE_URL}/remove`, {
      id: idList,
    });
  },
  save(driver) {
    if (driver.id) {
      return apiPost(`${SERVICE_URL}/${driver.id}`, driver.form);
    } else {
      return apiPost(`${SERVICE_URL}/add`, driver.form);
    }
  },
};
