import { find } from "lodash";
import { CarService } from "@/services/carService";

export default {
  namespaced: true,
  state: {
    cars: [],
    loading: false,
    free: [],
    currentCar: null,
  },
  getters: {
    getCars(state) {
      return state.cars;
    },
    getFreeCars(state) {
      return state.free;
    },
    getCarsSortedByRate(state) {
      return state.cars.sort((a, b) => {
        return a.rates[1].summ - b.rates[1].summ;
      });
    },
    getLoadingCars(state) {
      return state.loading;
    },
    getFreeById: (state) => (id) => find(state.free, (car) => car.id === id),
    getCurrentCar(state) {
      return state.currentCar;
    },
    getCarByGuid(state) {
      return (guid) => {
        return find(state.cars, (car) => car.guid === guid);
      };
    },
  },
  mutations: {
    loadCars(state, payload) {
      return (state.cars = payload);
    },
    loadFree(state, payload) {
      return (state.free = payload);
    },
    changeLoading(state, payload) {
      return (state.loading = payload);
    },
    setCurrentCar(state, payload) {
      return (state.currentCar = payload);
    },
  },
  actions: {
    async loadCars({ rootGetters, commit }) {

      commit("changeLoading", true);
      try {
        const response = await CarService.search({
          site: rootGetters["config/getSite"],
        });
        // const response = await axios("/api/cars.json", {
        //   query: { site: rootGetters["config/getSite"] },
        // });

        commit("loadCars", response);
      } catch (e) {
        console.log(e);
      } finally {
        commit("changeLoading", false);
      }
    },
    async loadFreeCars({ commit, rootGetters }, { from, to }) {
      commit("changeLoading", true);

      try {
        // const response = await axios.api.freeCars({
        //   query: { from, to, site: rootGetters["config/getSite"] },
        // });
        const response = await CarService.searchFree({
          from,
          to,
          site: rootGetters["config/getSite"],
        });

        commit("loadFree", response);
      } catch (e) {
        console.log(e);
      } finally {
        commit("changeLoading", false);
      }
    },
    setCurrentCar({ commit }, car) {
      commit("setCurrentCar", car);
    },
  },
};
