<template>
  <div>
    <side-bar />
    <div class="app__content">
      <div class="app__wrap">
        <slot></slot>
      </div>
      <div class="app__footer">
        <main-footer />
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/layout/SideBar";
import MainFooter from "@/components/layout/MainFooter";
export default {
  name: "MainLayout",
  components: { MainFooter, SideBar },
};
</script>

<style scoped lang="scss">
.app__content {
  padding-left: 345px;
  height: 100%;
  padding-top: 35px;
  max-width: 100vw;
  min-height: 100vh;
  @include media(1200) {
    padding-left: 0;
    padding-top: 90px;
  }
}
</style>
