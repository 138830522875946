<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 292.2 73.7"
    enable-background="new 0 0 292.2 73.7"
    xml:space="preserve"
  >
    <g>
      <path
        fill="#EB661A"
        d="M0.1,0.5c0.3,0,0.6,0,0.8,0c13.5,0,27.1,0,40.6,0c1.3,0,2.6,0.1,3.8,0.3c1.1,0.1,2.3,0.3,3.4,0.4
		c2.4,0.4,4.7,0.9,7,1.8c1.9,0.7,3.6,1.8,5.3,3c0.4,0.3,0.8,0.6,1.3,0.9c2.6,1.9,4.3,4.5,5.2,7.5c0.7,2.2,0.9,4.4,0.7,6.6
		c-0.3,3.6-1.5,6.8-3.7,9.6c-1.3,1.6-2.8,2.9-4.6,4c-0.3,0.2-0.7,0.4-1,0.6c-0.1,0.1-0.2,0.2-0.4,0.3c0.1,0.1,0.2,0.1,0.3,0.1
		c0.6,0.2,1.2,0.4,1.7,0.7c1.4,0.7,2.8,1.5,4,2.5c3.5,2.6,5.7,6.1,6.7,10.3c0.3,1.3,0.5,2.7,0.5,4c0,3.4-0.7,6.6-2.4,9.6
		c-1.2,2.1-2.8,3.9-4.8,5.3c-1.9,1.4-4,2.4-6.2,3.2c-2.4,0.9-4.9,1.5-7.4,1.9c-1.6,0.2-3.3,0.4-4.9,0.5c-0.1,0-0.2,0.1-0.4,0.1
		c-15.2,0-30.5,0-45.7,0C0.1,49.4,0.1,25,0.1,0.5z M24.4,56.5c0.3,0,0.5,0,0.8,0c4.8,0,9.6,0,14.4,0c0.6,0,1.3,0,1.9-0.1
		c0.9-0.1,1.8-0.3,2.6-0.7c1-0.5,1.8-1.2,2.3-2.2c0.7-1.5,0.8-3,0.3-4.5c-0.4-1.3-1.3-2.3-2.6-2.9c-0.4-0.2-0.9-0.3-1.3-0.5
		c-1.1-0.3-2.2-0.4-3.4-0.4c-4.8,0-9.6,0-14.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0.1C24.4,49,24.4,52.7,24.4,56.5z M24.4,17.9
		c0,0.2,0,0.4,0,0.5c0,0.8,0,1.6,0,2.3c0,2.4,0,4.7,0,7.1c0,0.2-0.1,0.4,0.1,0.7c0.2,0,0.4,0,0.6,0c4,0,7.9,0,11.9,0
		c0.6,0,1.2-0.1,1.8-0.1c1-0.2,1.9-0.5,2.8-1c1-0.7,1.7-1.5,2-2.7c0.2-0.8,0.2-1.5,0.1-2.3c-0.2-1.4-0.9-2.5-2.1-3.3
		c-0.5-0.3-0.9-0.5-1.5-0.7c-1-0.3-2-0.5-3.1-0.5c-4,0-8,0-12.1,0C24.8,17.9,24.6,17.9,24.4,17.9z"
      />
      <path
        fill="#EB661A"
        d="M73.9,73.9c0-0.2,0-0.5,0-0.7c0-23.9,0-47.9,0-71.8c0-0.3,0-0.5,0-0.8c0.1,0,0.3-0.1,0.4-0.1
		c0.2,0,0.3,0,0.5,0c6.2,0,12.5,0,18.7,0c0.2,0,0.5,0,0.7,0c1.4,1.9,3,3.7,4.4,5.4c2.3,2.8,4.7,5.6,7,8.4c1.5,1.8,3,3.5,4.5,5.3
		c2.1,2.6,4.2,5.1,6.3,7.7c1.5,1.9,3,3.7,4.6,5.5c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1
		c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0-10.5,0-21,0-31.5c0,0,0,0,0-0.1
		c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0.1-0.1
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c7.5,0,15,0,22.4,0c0.2,0,0.5,0,0.7,0c0,0.2,0,0.3,0,0.4c0,0.2,0,0.5,0,0.7
		c0,24.1,0,48.2,0,72.2c-6.7,0-13.4,0-20.1,0c-0.4-0.3-0.7-0.6-1-1c-0.1-0.2-0.3-0.3-0.4-0.5c-1.5-1.8-3.1-3.7-4.6-5.5
		c-3.9-4.7-7.8-9.4-11.7-14.1c-3.1-3.8-6.3-7.6-9.4-11.3c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2,0.3-0.1,0.5-0.1,0.8c0,10.4,0,20.8,0,31.2
		c0,0.3,0,0.6-0.1,0.8C89.8,73.9,81.8,73.9,73.9,73.9z"
      />
      <path
        fill="#EB661A"
        d="M292.4,0.4c-2,2.8-4,5.5-6,8.3c-2.8,3.9-5.6,7.7-8.4,11.6c-1.6,2.2-3.2,4.5-4.8,6.7
		c-0.6,0.8-1.1,1.5-1.7,2.3c-0.1,0.2-0.3,0.4-0.4,0.6c0,0,0,0-0.1,0c0,0,0,0-0.1,0c-37.7,0-75.4,0-113.1,0c-0.1,0-0.2,0-0.3,0
		c0,0,0,0-0.1-0.1c0-0.1,0-0.2,0-0.3c0-0.2,0-0.3,0-0.5c0-9.4,0-18.7,0-28.1c0-0.3,0-0.6,0-0.8c45,0,90,0,135,0
		C292.4,0.2,292.4,0.3,292.4,0.4z M237.8,6.4c-0.1,0.2-0.2,0.3-0.3,0.5c-0.8,1.7-1.5,3.4-2.2,5.1c-1.3,2.9-2.5,5.8-3.8,8.7
		c-0.1,0.2-0.1,0.4-0.4,0.6c-1.4-2.4-2.8-4.8-4.2-7.3c0.4-0.7,0.8-1.3,1.2-1.9c0.4-0.6,0.7-1.2,1.1-1.8c0.4-0.6,0.8-1.3,1.2-1.9
		c0.4-0.6,0.8-1.2,1.1-1.9c-0.6-0.1-4.9-0.1-5.4,0c-0.1,0.2-0.3,0.5-0.4,0.8c-0.8,1.5-1.7,2.9-2.5,4.4c-0.1,0.2-0.2,0.4-0.4,0.6
		c-0.5,0-1,0.1-1.5-0.1c0-0.2,0-0.5,0-0.7c0-1.5,0-3,0-4.6c0-0.2,0-0.3,0-0.5c-0.1,0-0.2,0-0.2,0c-1.6,0-3.1,0-4.7,0
		c-0.1,0-0.2,0-0.3,0.1c0,0.1,0,0.2,0,0.4c0,5,0,10,0,15.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0.1,0,0.1,0c1.5,0,3,0,4.5,0
		c0.2,0,0.3,0,0.5,0c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1c0-0.2,0-0.4,0-0.6c0-1.5,0-2.9,0-4.4c0-0.2,0-0.3,0-0.5c0.5,0,0.9,0,1.4,0
		c0.1,0.2,0.2,0.4,0.3,0.6c0.8,1.5,1.6,3,2.4,4.4c0.1,0.2,0.2,0.4,0.3,0.6c1.8,0,3.5,0,5.2,0c0.2-0.1,0.2-0.4,0.4-0.5
		c0.1,0.2,0.3,0.3,0.4,0.5c1.6,0,3.1,0,4.7,0c0.1-0.2,0.2-0.4,0.3-0.7c0.2-0.5,0.4-1.1,0.6-1.6c0.1-0.1,0.1-0.3,0.2-0.4
		c0.1,0,0.1,0,0.2,0c1.8,0,3.6,0,5.4,0c0.1,0,0.2,0,0.3,0.1c0.4,0.9,0.7,1.8,1.1,2.7c0.2,0,0.4,0,0.5,0c1.6,0,3.1,0,4.7,0
		c0,0,0,0,0.1,0c0,0,0,0,0.1-0.1c-0.5-1.3-1.1-2.6-1.7-3.9c-0.6-1.3-1.1-2.6-1.7-3.9c-0.6-1.3-1.1-2.6-1.7-3.9
		c-0.6-1.3-1.1-2.6-1.7-3.9C241.2,6.4,239.6,6.4,237.8,6.4z M205.6,22.5c0.7,0,1.7-0.1,2.7-0.4c4.2-1.3,6.5-5.1,5.8-9.3
		c-0.5-2.8-2.2-4.8-4.9-5.9c-2.3-1-4.7-1-7.1-0.2c-1.5,0.5-2.8,1.4-3.8,2.6c-1.4,1.8-1.9,3.9-1.7,6.1c0.4,2.8,1.8,4.8,4.3,6.1
		C202.2,22.1,203.7,22.4,205.6,22.5z M172.9,22c0.1,0,0.2,0.1,0.3,0.1c1.5,0,3.1,0,4.6,0c0.1,0,0.2,0,0.3-0.1c0-0.2,0-0.4,0-0.6
		c0-4.9,0-9.9,0-14.8c0,0,0,0,0-0.1c0,0,0,0-0.1-0.1c-0.2,0-0.4,0-0.7,0c-2.7,0-5.5,0-8.2,0c-1.8,0-3.7,0-5.5,0
		c-0.2,0-0.4-0.1-0.6,0.1c0,0.2,0,0.4,0,0.7c0,3.8,0,7.7,0,11.5c0,1,0,1.9,0,2.9c0,0.1,0,0.3,0.1,0.5c0.4,0,0.7,0,1.1,0
		c0.3,0,0.7,0,1,0c0.3,0,0.7,0,1,0c0.3,0,0.7,0,1,0c0.3,0,0.7,0,1-0.1c0-0.2,0-0.5,0-0.7c0-1.4,0-2.9,0-4.3c0-2,0-3.9,0-5.9
		c0-0.2-0.1-0.4,0.1-0.6c0.1,0,0.1,0,0.1,0c1.4,0,2.8,0,4.2,0c0.1,0,0.2,0,0.3,0.1c0,0.8,0,1.5,0,2.3c0,0.8,0,1.5,0,2.3s0,1.5,0,2.3
		c0,0.8,0,1.5,0,2.3C172.8,20.5,172.8,21.3,172.9,22z M186.1,18.3c0.3,0,0.6,0,0.8,0c1,0,1.9,0,2.9-0.1c1-0.1,2-0.4,3-0.9
		c1.3-0.7,2.2-1.8,2.6-3.3c0.2-0.7,0.2-1.4,0.2-2.1c-0.1-1.2-0.5-2.3-1.3-3.2c-0.6-0.7-1.4-1.2-2.3-1.6c-1.1-0.4-2.2-0.6-3.3-0.6
		c-2.5,0-4.9,0-7.4,0c-0.1,0-0.3,0-0.4,0c0,0.2-0.1,0.3-0.1,0.5c-0.1,3.8,0,14.5,0.1,15.2c0.4,0,0.8,0,1.3,0c0.4,0,0.9,0,1.3,0
		s0.9,0,1.3,0c0.4,0,0.9,0,1.3,0C186.1,20.8,186.1,19.6,186.1,18.3z M263.8,6.4c-0.9-0.1-13.8,0-14.3,0c-0.1,0.4,0,0.9,0,1.3
		c0,0.4,0,0.9,0,1.3c0,0.4-0.1,0.9,0.1,1.4c0.2,0,0.5,0,0.7,0c1.1,0,2.2,0,3.3,0c0.2,0,0.3,0,0.5,0.1c0,0.3,0,0.5,0,0.7
		c0,1.7,0,3.4,0,5.1c0,1.7,0,3.4,0,5.2c0,0.2-0.1,0.4,0.1,0.6c0,0,0.1,0,0.1,0c1.6,0,3.1,0,4.7,0c0.1,0,0.2,0,0.3-0.1
		c0-0.3,0-0.5,0-0.7c0-2.9,0-5.7,0-8.6c0-0.6,0-1.2,0-1.8c0-0.1,0-0.2,0.1-0.4c0.5,0,1,0,1.5,0c0.5,0,1,0,1.4,0c0.5,0,1,0.1,1.5-0.1
		c0-0.1,0.1-0.1,0.1-0.2c0-1.2,0-2.4,0-3.6C263.9,6.6,263.8,6.5,263.8,6.4z"
      />
      <path
        fill="#EB661A"
        d="M239.8,73.5c-0.7,0.1-81.8,0.1-82.4,0c0-0.1,0-0.2,0-0.4c0-0.2,0-0.3,0-0.5c0-9.4,0-18.7,0-28.1
		c0-0.3,0-0.5,0-0.8c0.1,0,0.1-0.1,0.2-0.1c34.4,0,68.8,0,103.2,0c0.1,0,0.1,0,0.2,0.1c0,0.1-0.1,0.2-0.1,0.3
		c-0.2,0.3-0.3,0.5-0.5,0.8c-2.8,3.9-5.6,7.8-8.4,11.6c-3.6,5-7.2,10-10.8,15C240.8,72.1,240.3,72.8,239.8,73.5z M183.8,66.8
		c0.3,0,0.5,0,0.7,0c2.4,0,4.8,0,7.2,0c0.7,0,1.3,0,2-0.1c1-0.1,1.9-0.4,2.7-0.8c1.2-0.7,1.9-1.7,2-3c0.2-1.6-0.4-3.2-2-4
		c-0.2-0.1-0.3-0.2-0.6-0.3c0.1-0.1,0.2-0.2,0.3-0.2c1.2-0.8,1.7-2,1.6-3.4c-0.1-1.1-0.6-2-1.5-2.6c-0.6-0.4-1.2-0.7-1.8-0.8
		c-0.8-0.2-1.7-0.3-2.5-0.3c-2.6,0-5.2,0-7.8,0c-0.1,0-0.2,0-0.4,0C183.7,51.8,183.7,66.2,183.8,66.8z M223,67.1
		c1,0,2.1-0.1,3.1-0.4c3.7-1.2,6.2-4.5,5.7-8.8c-0.3-2.8-1.8-4.9-4.3-6.1c-2.6-1.3-5.3-1.4-8-0.4c-3.2,1.2-5.7,4.3-5.2,8.7
		c0.4,2.9,2,5,4.7,6.2C220.2,66.9,221.5,67.1,223,67.1z M168.8,66.8c0.4-0.9,0.7-1.8,1-2.7c0.1,0,0.2,0,0.3,0c1.8,0,3.7,0,5.5,0
		c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.4,0.9,0.7,1.8,1.1,2.6c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0,0.7,0c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0,0.7,0
		s0.4,0,0.7,0c0.2,0,0.4,0,0.7,0c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0,0.6,0c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
		c-2.2-5.1-4.4-10.1-6.7-15.2c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.8-0.1-4.7,0-5.2,0c-2.2,5.1-4.5,10.2-6.7,15.4c0,0.1,0,0.1,0,0.2
		C165.2,66.8,166.9,66.8,168.8,66.8z M203.8,66.7c0.1,0,0.2,0,0.2,0c1.5,0,3.1,0,4.6,0c0.1,0,0.2,0,0.3-0.1c0-0.2,0-0.4,0-0.7
		c0-1.9,0-3.8,0-5.7c0-1.5,0-3,0-4.5c0-0.2-0.1-0.4,0.1-0.6c0.7,0,1.4,0,2.2,0c0.7,0,1.5,0,2.2,0c0.1-0.6,0.1-3.5,0-4
		c-0.1,0-0.2,0-0.4,0c-3.2-0.1-13.3,0-13.9,0.1c-0.1,0.4,0,0.9-0.1,1.3c0,0.4,0,0.9,0,1.3c0,0.4-0.1,0.9,0.1,1.4c0.5,0,1,0,1.5,0
		c0.5,0,1,0,1.4,0c0.5,0,1,0,1.5,0c0.2,0.3,0.1,0.5,0.1,0.7c0,3.3,0,6.7,0,10C203.7,66.2,203.7,66.5,203.8,66.7z"
      />
      <path
        fill="#EB661A"
        d="M238.9,15.5c0.1-0.5,1.1-3.2,1.4-3.7c0,0,0.1,0.1,0.1,0.1c0.4,1.1,0.9,2.3,1.3,3.4c0,0.1,0,0.1,0,0.2
		C241.1,15.6,239.4,15.5,238.9,15.5z"
      />
      <path
        fill="#EB661A"
        d="M201.8,14.2c0-0.6,0.1-1.2,0.4-1.8c0.6-1.3,1.6-2,3.1-2.1c1.4,0,2.5,0.6,3.2,1.9c0.7,1.3,0.6,2.7,0,4.1
		c-0.6,1.2-1.6,1.8-3,1.8c-1.4,0-2.4-0.6-3-1.8C201.9,15.7,201.8,15,201.8,14.2z"
      />
      <path
        fill="#EB661A"
        d="M186.2,10.5c0.1,0,0.1-0.1,0.1-0.1c0.6,0,1.1,0,1.7,0c0.3,0,0.6,0,0.8,0.1c0.2,0,0.3,0.1,0.5,0.2
		c0.4,0.2,0.6,0.5,0.7,0.9c0.1,0.4,0.2,0.9,0,1.3c-0.2,0.8-0.8,1.1-1.5,1.2c-0.7,0.1-1.5,0-2.2,0c-0.1,0-0.1,0-0.2-0.1
		c0-0.1-0.1-0.1-0.1-0.2c0-1.1,0-2.1,0-3.2C186.1,10.7,186.2,10.6,186.2,10.5z"
      />
      <path
        fill="#EB661A"
        d="M189,63c0-0.8,0-1.5,0-2.3c0.1,0,0.3-0.1,0.4-0.1c0.8,0,1.7,0,2.5,0c0,0,0.1,0,0.1,0c0.4,0,0.8,0.1,1,0.6
		c0.2,0.5,0.2,1-0.1,1.4c-0.1,0.2-0.4,0.3-0.6,0.4c-0.1,0-0.3,0-0.5,0c-0.8,0-1.7,0-2.5,0C189.2,63.1,189.1,63,189,63z"
      />
      <path
        fill="#EB661A"
        d="M189,57.1c-0.1-0.6-0.1-1.7,0-2.3c0.1,0,0.3,0,0.4,0c0.5,0,1.1,0,1.6,0c0.1,0,0.1,0,0.2,0
		c0.9,0,1.4,0.7,1.2,1.5c-0.1,0.3-0.3,0.6-0.6,0.7c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0-0.3,0-0.5,0C190.2,57.1,189.6,57.1,189,57.1z"
      />
      <path
        fill="#EB661A"
        d="M226.5,58.9c0,0.7-0.1,1.3-0.4,1.9c-0.6,1.3-1.7,2-3.1,2c-1.4,0-2.5-0.6-3.1-2c-0.6-1.3-0.6-2.6,0-3.9
		c0.6-1.3,1.7-2,3.2-2c1.5,0,2.5,0.7,3.1,2.1C226.4,57.7,226.5,58.3,226.5,58.9z"
      />
      <path
        fill="#EB661A"
        d="M174.2,60.2c-0.6,0.1-2.3,0.1-2.8,0c0.1-0.5,0.8-2.5,1.4-3.7c0.1,0.1,0.2,0.1,0.2,0.2
		c0.4,1.1,0.8,2.1,1.2,3.2C174.2,60,174.2,60.1,174.2,60.2z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SvgLogo",
};
</script>
