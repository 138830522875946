import { index } from "@/modules/client/services/notificationService";

export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  getters: {
    hasUnreadNotification(state) {
      return state.notifications.find(
        (notification) => notification.read === 0
      );
    },
  },
  mutations: {
    setNotifications(state, payload) {
      state.notifications = payload;
    },
  },
  actions: {
    async fetchNotifications({ commit }) {
      const notifications = await index();
      commit("setNotifications", notifications);
    },
  },
};
