import { apiGet } from "@/modules/client/services";

export const CarService = {
  search(query) {
    return apiGet("/api/cars", query);
  },
  searchFree(query) {
    return apiGet("/api/cars/free", query);
  },
};
