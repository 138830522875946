import ClientLayout from "@/modules/client/layouts/ClientLayout";

export default [
  {
    path: "/client/dashboard",
    name: "ClientDashboard",
    component: () => import("@/modules/client/views/ClientDashboard"),
    meta: {
      auth: true,
      layout: ClientLayout,
      title: " "
    },
  },
  {
    path: "/client/rent/",
    name: "ClientRent",
    component: () => import("@/modules/client/views/ClientRentHistory"),
    meta: {
      auth: true,
      layout: ClientLayout,
      title: "Мои заявки",
    },
  },
  {
    path: "/client/favorites/",
    name: "ClientFavorites",
    component: () => import("@/modules/client/views/ClientDashboard"),
    meta: {
      auth: true,
      layout: ClientLayout,
      title: "Избранное",
    },
  },
  {
    path: "/client/messages/",
    name: "ClientMessages",
    component: () => import("@/modules/client/views/ClientDashboard"),
    meta: {
      auth: true,
      layout: ClientLayout,
      title: "Сообщения",
    },
  },
  {
    path: "/client/wallet/",
    name: "ClientWallet",
    component: () => import("@/modules/client/views/ClientDashboard"),
    meta: {
      auth: true,
      layout: ClientLayout,
      title: "Кошелек",
    },
  },
  {
    path: "/client/settings",
    name: "ClientSettings",
    component: () => import("@/modules/client/views/ClientSettings"),
    meta: {
      auth: true,
      layout: ClientLayout,
      title: "Карточка клиента",
    },
  },
  {
    path: "/client/order",
    name: "ClientOrder",
    component: () => import("@/modules/client/views/ClientOrder"),
    meta: {
      auth: true,
      layout: ClientLayout,
      title: "Новая заявка",
    },
  },
  {
    path: "/client/drivers",
    name: "ClientDrivers",
    component: () => import("@/modules/client/views/ClientDrivers"),
    meta: {
      auth: true,
      layout: ClientLayout,
      title: "Водители",
    },
  },
];
