var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"form vstack m-auto",on:{"submit":function($event){$event.preventDefault();return _vm.auth.apply(null, arguments)}}},[_c('router-link',{staticClass:"d-block mb-4",attrs:{"to":"/"}},[_c('svg-logo')],1),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"form__item"},[_vm._v(" Телефон "),_c('input',{directives:[{name:"maska",rawName:"v-maska"},{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],attrs:{"data-maska":"+7(###)### ##-##","type":"text"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.phone=$event.target.value}}})])]),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"form__item"},[_vm._v(" Пароль "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})])]),_c('button',{staticClass:"mt-3",attrs:{"type":"submit"}},[_vm._v("Войти")]),(_vm.lastError)?_c('div',{staticClass:"alert alert-warning mt-3"},[_vm._v(_vm._s(_vm.lastError))]):_vm._e(),_c('router-link',{staticClass:"mt-4",attrs:{"to":{
      name: _vm.$route.name,
      query: { ..._vm.$route.query, forget: true },
    }}},[_vm._v("Забыли пароль?")]),_c('v-divider'),_c('p',[_vm._v(" У вас нет аккаунта ? "),_c('router-link',{staticClass:"text-uppercase",attrs:{"to":{
        name: this.$route.name,
        query: { ...this.$route.query, register: true },
      }}},[_vm._v("регистрация")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }