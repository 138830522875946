<template>
  <div id="app" class="app">
    <component :is="layout">
      <router-view />
    </component>
    <div class="popup_bg" id="popup-bg" />

    <transition
      name="fade"
      enter-active-class="fadeInRight"
      leave-active-class="fadeOutRight"
    >
      <car-modal v-if="$route.query.car" style="animation-duration: 0.5s" />
      <success-modal
        v-if="$route.query.success"
        style="animation-duration: 0.5s"
      />
      <auth-modal v-if="!isAuth && $route.query.auth"></auth-modal>
    </transition>
  </div>
</template>

<style lang="scss"></style>
<script>
import SideBar from "@/components/layout/SideBar";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Cookies from "js-cookie";
import geoService from "@/services/geoService";
import CarModal from "@/components/modals/CarModal";
import SuccessModal from "@/components/modals/SuccessModal";
import MainFooter from "@/components/layout/MainFooter";
import AuthModal from "@/components/modals/AuthModal";
import MainLayout from "@/components/layout/MainLayout";

export default {
  components: { AuthModal, MainFooter, SuccessModal, CarModal, SideBar },
  computed: {
    ...mapGetters("config", [
      "getCityList",
      "getCity",
      "getSite",
      "getActiveCity",
      "getAuthToken",
    ]),
    ...mapGetters("user", ["isAuth"]),
    routeFrom() {
      return this.$route.query.from;
    },
    routeTo() {
      return this.$route.query.to;
    },
    layout() {
      return this.$route.meta.layout || MainLayout;
    },
  },

  methods: {
    ...mapMutations("config", ["changeSite"]),
    ...mapActions("config", ["loadCity"]),
    ...mapActions("content", ["loadMainStock"]),
    ...mapActions("car", ["loadCars", "loadFreeCars"]),
    ...mapActions("user", ["authViaToken"]),
    async loadCarsMethod() {
      await this.loadCars();
      if (this.$route.query.from && this.$route.query.to) {
        await this.loadFreeCars({ ...this.$route.query });
      }
    },
    getCityByCookie() {
      if (Cookies.get("city")) return (this.activeCity = Cookies.get("city"));
      else {
        geoService.getGeo(this.getCityList, this.$store);
      }
    },
  },
  watch: {
    getCity(val) {
      this.$axios.baseInstance.defaults.headers["Region"] = val;
    },
    getSite() {
      this.loadCarsMethod();
    },
  },
  async mounted() {
    await this.getCityByCookie();
    await this.loadMainStock();
    if (!this.getCityList.length) {
      await this.loadCity();
    }
    if (this.getActiveCity.id) {
      this.$axios.baseInstance.defaults.headers["Region"] = this.getCity;
      await this.changeSite(
        +Cookies.get("site") || this.getActiveCity.sites?.[0]?.id
      );
      // await this.loadCarsMethod();
    }
  },
};
</script>
<style lang="scss" scoped>
.app {
  background-color: #f4f4f4;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

.popup_bg.active {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, 0.4);
}
</style>
