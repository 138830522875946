import { apiPost } from "./index";

export function detail() {
  return apiPost("/api/auth/me");
}

export function updateInfo() {
  return apiPost('/api/personal/info/update')
}

export function login(credentials) {
  return apiPost("/api/auth/login", credentials);
}

export function save(client, id = null) {
  return apiPost(id ? `/api/personal/${id}` : "/api/personal", client);
}

export function order(form) {
  return apiPost("/api/applications", form);
}

export function changePassword({current,password,repeat}) {
  return apiPost("/api/me/change-password", {
    current,password,repeat
  });
}
