import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ru from "vuetify/es5/locale/ru";
import { definePlugin } from "./plugin";

export const VuetifyPlugin = definePlugin(({ settings }) => {
  Vue.use(Vuetify);
  settings.vuetify = new Vuetify({
    lang: {
      locales: { ru },
      current: "ru",
    },
    theme: {
      dark: false,
      themes: {
        light: {
          // Color is applied to selected drop down item
          primary: "#fe6500",

          // Uncomment this to make things worse
          // 'primary--text': '#FF00FF'
        },
      },
    },
  });
});
