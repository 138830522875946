<template>
  <v-navigation-drawer
    :absolute="isMobile"
    :temporary="isMobile"
    :stateless="!isMobile"
    :value="value"
    rail
    @input="$emit('input', $event)"
  >
    <v-list>
      <v-list-item>
        <svg-logo></svg-logo>
      </v-list-item>
      <v-list-item>
        <div class="mt-2 font-weight-bold">
          {{ getCurrentDate }}
          <br />
          {{ getCurrentDay }}
        </div>
      </v-list-item>
    </v-list>

    <v-list>
      <!--      <v-list-item>-->
      <!--        <v-list-item-avatar size="110" class="overflow-visible">-->
      <!--          <img src="https://via.placeholder.com/300x300" alt="" />-->
      <!--          <div class="avatar-edit-badge">-->
      <!--            <v-icon>mdi-pencil</v-icon>-->
      <!--          </div>-->
      <!--        </v-list-item-avatar>-->
      <!--      </v-list-item>-->
      <v-list-item>
        <v-list-item-content>
          <div class="text-h6">{{ formatFio(user.client) }}</div>
          <div class="text-subtitle-1 font-weight-light">
            {{ formatedPhone }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title> Меню </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <v-list-item
        v-for="link in links"
        :key="link.title"
        :title="link.href"
        :to="link.href"
        :disabled="link.disabled"
        :target="(link.href && link.target) || null"
      >
        <v-list-item-icon>
          <v-icon icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ link.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <v-list-item title="Выйти" to="/client/?logout">
        <v-list-item-icon>
          <v-icon icon>mdi-logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Выйти</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import SvgLogo from "@/components/svg/SvgLogo";
import { mapGetters } from "vuex";
import { formatFio } from "./../formats/fio";
import { Mask } from "maska";
export default {
  name: "SideBar",
  props: {
    value: {
      type: Boolean,
    },
  },
  components: { SvgLogo },
  computed: {
    ...mapGetters("user", ["user"]),
    formatedPhone() {
      return new Mask({ mask: "+# (###) ###-##-##" }).masked(this.user.phone);
    },
    isMobile() {
      return this.width <= 959;
    },
    getCurrentDate() {
      return new Date().toLocaleDateString("ru-RU");
    },
    getCurrentDay() {
      return new Date().toLocaleDateString("ru-RU", {
        weekday: "long",
      });
    },
  },
  data: () => ({
    width: window.innerWidth,
    links: [
      {
        href: "/client/dashboard",
        title: "Главная страница",
        icon: "mdi-view-dashboard",
      },
      {
        href: "/client/rent",
        title: "Мои заявки",
        icon: "mdi-car",
      },
      {
        href: "/client/settings",
        title: "Персональные данные",
        icon: "mdi-calendar-blank",
      },
      {
        href: "/client/drivers",
        title: "Водители",
        icon: "mdi-star",
      },
      {
        href: "/client/order",
        title: "Новая заявка",
        icon: "mdi-chat",
      },
      {
        href: "/client/wallet",
        title: "Оплата проката",
        icon: "mdi-wallet-bifold",
        disabled: true,
      },
      {
        href: "/client/messages",
        title: "Чат",
        icon: "mdi-cog-outline",
        disabled: true,
      },
      {
        href: "/rental-rules",
        title: "Правила проката",
        icon: "mdi-account-multiple",
        target: "_blank",
      },

      // {
      //   href: "/client/favorites",
      //   title: "Избранное",
      //   icon: "mdi-star",
      // },
      // {
      //   href: "/client/?logout",
      //   title: "Выйти",
      //   icon: "mdi-logout",
      // },
    ],
  }),
  methods: {
    formatFio(args) {
      return formatFio(args);
    },
    updateWindowWidth() {
      this.width = window.innerWidth;
      if (!this.isMobile) {
        this.$emit("input", true);
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },
};
</script>

<style scoped lang="scss">
.v-list-item {
  &.v-list-item--active {
    background: #ffdbdb;
    color: #ff7171;
  }

  a {
    color: #717579;
    text-decoration: none;
    width: 100%;
    padding: 13px 20px;
  }

  .avatar-edit-badge {
    position: absolute;
    left: 60%;
    top: 60%;
    background: #f4f4f4;
    border: 5px solid white;
    padding: 10px;
    border-radius: 50%;
  }
}
</style>
