import ClientPlugins from "@/modules/client/plugins";
import { FancyboxPlugin } from "./fancyapps";
import { VuetifyPlugin } from "./vuetify";
import { MomentPlugin } from "./moment";
import { MaskaPlugin } from "./maska";

export const plugins = [
  FancyboxPlugin,
  VuetifyPlugin,
  MomentPlugin,
  MaskaPlugin,
  ...ClientPlugins,
];
