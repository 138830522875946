<template>
  <div class="popup">
    <div class="popup__close" @click="closeCar">
      <svg
        width="62"
        height="62"
        padding="12px"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="padding: 12px"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30.396 31.0909L58.9647 59.6202L59.7035 58.8822L31.1348 30.353L59.7035 1.82387L58.9647 1.08594L30.396 29.6152L1.82627 1.08594L1.0874 1.82384L29.6561 30.353L1.0874 58.8823L1.82627 59.6202L30.396 31.0909Z"
          fill="black"
          stroke="black"
          stroke-width="2"
        />
      </svg>
    </div>

    <div
      class="popup__wrapper d-flex align-items-center justify-content-center"
    >
      <div class="popup__content">
        <svg
          width="189"
          height="188"
          viewBox="0 0 189 188"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M94.5 182.125C143.429 182.125 183.094 142.67 183.094 94C183.094 45.3299 143.429 5.875 94.5 5.875C45.571 5.875 5.90625 45.3299 5.90625 94C5.90625 142.67 45.571 182.125 94.5 182.125Z"
            fill="#4BD37B"
          />
          <path
            d="M135.844 41.125L73.8281 104.575L53.1562 83.425L32.4844 104.575L73.8281 146.875L156.516 62.275L135.844 41.125Z"
            fill="white"
          />
        </svg>
        <p class="pt-3">
          Заявка №{{ $route.query.success }} успешно отправлена
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessModal",
  mounted() {
    document.body.style.overflow = "hidden";
    document.getElementById("popup-bg").classList.add("active");
    document.addEventListener("click", this.clickToWrap);
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
    document.getElementById("popup-bg").classList.remove("active");
    document.removeEventListener("click", this.clickToWrap);
  },
  methods: {
    clickToWrap(e) {
      if (e.target.classList.contains("popup")) {
        this.closeCar();
      }
    },
    closeCar() {
      return this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, success: undefined },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.popup {
  width: 100%;
  left: 0;
  //background-color: rgba(#000, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-modal;
  bottom: 0;
  &__wrapper {
    background-color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 600px;
    width: 50vw;
    min-width: 320px;
    overflow-y: auto;
    overflow-x: visible;
  }
  &__close {
    position: absolute;
    top: 10px;
    left: 15vw;
    cursor: pointer;
    display: block;
    z-index: 30;
    background: gray;
    border-radius: 50%;
    @include media(768) {
      display: block;
      svg {
        height: auto;
        max-width: 30px;
      }
    }
  }
  &__content {
    text-align: center;
    svg {
      max-width: 100%;
      height: auto;
    }
    p {
      font-weight: 700;
      font-size: em(27);
      text-align: center;

      color: #171930;
    }
  }
}
</style>
